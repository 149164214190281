import React from 'react';
import { Link } from 'react-router-dom';

const Support = () => {
  return (
    <div className="bg-[#495057] font-semibold text-white font-serif min-h-screen flex flex-col justify-between">
      <div className="flex flex-col md:flex-row md:w-[1200px] min-h-screen items-center justify-center h-full mx-auto md:max-w-6xl">

        <div className="flex flex-col items-center text-center">
          <Link to="/"><img src="images/icon.png" alt="Logo" className="pixelImg w-80 mb-7 mt-8 md:mt-0" /></Link>

          <div className="tracking-wider px-5">
            <h1 className="font-joan mb-8 md:mb-6 text-3xl">Support</h1>

            <h2 className="font-joan text-xl my-8 md:my-0 md:mb-1">If you need support, or have any questions related to Piano Dash, contact me via email:</h2>
            <h2 className="font-joan text-xl my-8 md:my-0 md:mb-1"><a href="mailto:pianodashgame@gmail.com">pianodashgame@gmail.com</a></h2>
          </div>
        </div>
      </div>

      <footer className="bg-[#495057] text-white text-center py-2 text-sm">
        <a href="https://josephmalam.com/" target="_blank" className="font-joan px-2" rel="noopener noreferrer">&copy; Joseph Malam 2022-2024</a> &bull;
        <Link to="/support" className="px-2 font-joan">Support</Link>  &bull;
        <Link to="/privacy" className="px-2 font-joan">Privacy Policy</Link>
      </footer>
    </div>
  );
};

export default Support;
