// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPzyzsuGzE6ysMreOj4KazFH5hLkE9vaU",
  authDomain: "piano-dash-game.firebaseapp.com",
  projectId: "piano-dash-game",
  storageBucket: "piano-dash-game.appspot.com",
  messagingSenderId: "78349945064",
  appId: "1:78349945064:web:c39cc4ae7ec0d4df21615d",
  measurementId: "G-GNW4T47KGC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
