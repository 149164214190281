import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="bg-[#495057] font-semibold text-white font-serif min-h-screen flex flex-col justify-between">
      <div className="flex flex-col md:flex-row md:w-[1200px] min-h-screen items-center justify-center h-full mx-auto md:max-w-6xl">
        <div className="hidden md:block flex justify-center items-center relative mr-8">
          <div className="relative">
            <img src="images/iphoneOutline.png" alt="iPhone Outline" className="relative w-80 z-20" />
            <video loop muted autoPlay className="absolute w-72 top-3 left-4 z-10 rounded-3xl">
              <source src="/newvideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="absolute -top-6 -right-6 -rotate-6 bg-red-500 text-white rounded-full w-24 h-24 z-40 flex items-center justify-center text-4xl font-bold shadow-2xl">
            <div className='flex flex-col items-center'>
              <div className='font-joan text-base -mb-3'>ONLY</div>
              <div className='font-joan text-4xl'>99<span className='text-lg'>¢</span></div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center text-center">
          <img src="images/icon.png" alt="Logo" className="pixelImg w-[90%] mb-7 mt-8 md:mt-0" />

          <div className="tracking-wider px-5">
            <h1 className="font-joan mb-8 md:mb-6 text-3xl">Pianists, lace up your running shoes.</h1>

            <div className="block md:hidden flex justify-center items-center relative">
              <div className="relative">
                <img src="images/iphoneOutline.png" alt="iPhone Outline" className="relative w-80 z-20" />
                <video loop muted autoPlay className="absolute w-72 top-3 left-4 z-10 rounded-3xl">
                  <source src="/newvideo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="absolute -top-6 right-2 -rotate-6 bg-red-500 text-white rounded-full w-24 h-24 z-40 flex items-center justify-center text-4xl font-bold shadow-2xl">
                <div className='flex flex-col items-center'>
                  <div className='font-joan text-base -mb-3'>ONLY</div>
                  <div className='font-joan text-4xl'>99<span className='text-lg'>¢</span></div>
                </div>
              </div>
            </div>


            <h2 className="font-joan text-xl my-8 md:my-0 md:mb-1">Learn piano notation, <br className="block md:hidden" /> or show off your sight-reading skills.</h2>
            <h2 className="font-joan text-xl my-8 md:my-0 md:mb-6">Play as one of 6 pianists <br className="block md:hidden" /> as you identify notes and jump over them.</h2>

            <div className="flex flex-col items-center space-y-2 mb-6">
              <span className="font-joan flex items-center text-lg"><img src="images/beethoven.png" alt="Beethoven" className="pixelImg w-6 mr-2" /> Ludwig van BEETHOVEN</span>
              <span className="font-joan flex items-center text-lg"><img src="images/liszt.png" alt="Liszt" className="pixelImg w-6 mr-2" /> Franz LISZT</span>
              <span className="font-joan flex items-center text-lg"><img src="images/rachmaninoff.png" alt="Rachmaninoff" className="pixelImg w-6 mr-2" /> Sergei RACHMANINOFF</span>
              <span className="font-joan flex items-center text-lg"><img src="images/schumann.png" alt="Schumann" className="pixelImg w-6 mr-2" /> Clara SCHUMANN</span>
              <span className="font-joan flex items-center text-lg"><img src="images/joplin.png" alt="Joplin" className="pixelImg w-6 mr-2" /> Scott JOPLIN</span>
              <span className="font-joan flex items-center text-lg"><img src="images/mozart.png" alt="Mozart" className="pixelImg w-6 mr-2" /> Wolfgang Amadeus MOZART</span>
            </div>

            <div className='flex flex-row justify-between md:justify-center my-8 mt-12 md:mt-0 md:my-0'>
              <a href="https://apps.apple.com/gb/app/piano-dash/id1632441647" className="inline-block">
                <img src="images/appStore.svg" alt="Download on the App Store" className="h-[3.2rem] md:h-16 object-contain md:mr-4" />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.josephmalam.pianodash" className="inline-block">
                <img src="images/googlePlay.png" alt="Download on the Google Play Store" className="h-[3.2rem] md:h-16 object-contain" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-[#495057] text-white text-center py-2 text-sm">
        <a href="https://josephmalam.com/" target="_blank" className="font-joan px-2" rel="noopener noreferrer">&copy; Joseph Malam 2022-2024</a> &bull;
        <Link to="/support" className="px-2 font-joan">Support</Link>  &bull;
        <Link to="/privacy" className="px-2 font-joan">Privacy Policy</Link>
      </footer>
    </div>
  );
};

export default Home;
